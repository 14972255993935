import firebase from "firebase/app";

const fire = firebase.initializeApp({
    apiKey: "AIzaSyDGB063ifoS7uPV6cSBbNQzenm9x6a5n_w",
    authDomain: "chatroom-78839.firebaseapp.com",
    projectId: "chatroom-78839",
    storageBucket: "chatroom-78839.appspot.com",
    messagingSenderId: "916242390162",
    appId: "1:916242390162:web:55d9e52a48e93963a75a4b",
    measurementId: "G-5X58YEJ9KE"
  });

export default fire;