import { useState, useRef, useEffect } from "react";
import firebase from "firebase/app";
import fire from "../../firebase";
import "firebase/auth";
import "firebase/firestore";
import "./chats.css";
import Chat from "./chat";

function Chats(props) {
    const auth = fire.auth();
    const db = fire.firestore();
    const logo = {
        send : <svg viewBox="0 0 24 24" fill="white" width="30px" height="30px">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"/></svg>
    };
    const [input,setInput] = useState('');
    const [chat,setChat] = useState([]);
    const [hidden,visible] = useState('navbarOne');
    const scrl = useRef();

    useEffect(()=>{
        db.collection("test").orderBy('time','asc').onSnapshot(snap => {
            setChat(snap.docs.map(doc => ({
                id : doc.id,
                chat : doc.data().chat,
                sender : doc.data().sender,
            })))
        })
    },[db]);
    
    const toggle = (e) => {
        e.preventDefault();
        (hidden === "navbarOne") ? visible("navbar") : visible("navbarOne");
    }

    const addMessege = (e) => {
        e.preventDefault();
        scrl.current.scrollIntoView({behavior : 'smooth'});
        db.collection("test").add({
            chat : input,
            sender : props.userName,
            time : firebase.firestore.FieldValue.serverTimestamp(),
        })
        setInput('');
    }

    return (
        <div>
            <div className='chats'>
                {chat.map((i) => <Chat message={i} key={i.id}
                
                Style={`${(props.userName=== i.sender) ? 'styleTwo' : 'styleOne'}`}
                style={`${(props.userName=== i.sender) ? 'styleFour' : 'styleThree'}`}
                userName={props.userName} />)}
            </div>
            
            <form className="texts">
                <input type='text' className="text"
                 placeholder='Type your messege here'
                 onChange={(e)=>setInput(e.target.value)} value={input} />

                <button className='post' onClick={addMessege} disabled={!input}>{logo.send}</button>
            </form>

            <div ref={scrl}></div>

            <nav className={hidden}>
                <button className="btnOne" onClick={toggle}>=</button>
                <h2>
                    <span>{props.userName}</span>   
                </h2>
                <button className="exit" onClick={()=> auth.signOut()}>EXIT ROOM</button>
            </nav>
        </div>
    );
}

export default Chats;