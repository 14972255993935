import "./App.css";
import firebase from "firebase/app";
import fire from "./firebase";
import Login from "./Components/login/login";
import Chats from "./Components/chatroom/chats";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { useState } from "react";

function App() {
  const [user,setUser] = useState('');
  const [userName,setUserName] = useState('Sameer Ranjan Sahu');
  
  const auth = fire.auth();
  
  const google =(e) => {
    e.preventDefault();
    const provider = new firebase.auth.GoogleAuthProvider();

    auth.signInWithPopup(provider)
        .then((data)=>{
            setUser(data);
        })
        .catch((err)=>{
            const ec = err.code;
            const msg = err.message;
            console.log(ec)
            console.log(msg)
        })
    }
  auth.onAuthStateChanged((user) => {
    if (user){
      setUser(user);
      setUserName(user.displayName);
    } else {
      auth.signOut();
      setUser('');
    }
  })

  return (
    <div className="App">
      <Router>
        <Switch>
          {user
            ? <Route path="/" exact><Chats userName={userName} /></Route>
            : <Route path="/" exact><Login login={google} /></Route>
          }
         </Switch>
      </Router>  
    </div>
  );
}

export default App;
