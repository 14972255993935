import "./chats.css";
import fire from "../../firebase";

function chat(props) {
    const logo = {
        delete : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="red" width="18px" height="18px">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
    }
    return (
        <div className={props.style}>
            <span className={props.Style}>
                <span>
                    {props.message.chat}
                    <button className='delbtn' onClick={()=> {
                        fire.firestore().collection('test').doc(props.message.id).delete();
                    }}>{logo.delete}</button>
                </span>
                <small className="user">{props.message.sender}</small>
            </span>
            
        </div>
    );
}

export default chat;